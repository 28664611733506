.mobile-header {
	z-index: 0;
	&_text-input {
		&.menu {
			.g-text-input__control_type_input {
				text-align: center;
			}
		}

		&.search {
			.g-text-input__control_type_input {
				padding-left: 0;
				text-align: left;
			}
		}

		.g-text-input {
			&__content {
				background-color: var(--g-color-base-background);
			}
		}
	}

	&.search-bar {
		padding-top: 0;
		padding-bottom: 0;
		border-bottom-width: 1px;
		border-bottom-style: solid;
		border-bottom-color: var(--g-color-line-generic);
		margin-bottom: -1px;
	}

	&_search-button {
		flex-grow: 1;

		.g-button__text {
			justify-content: center;
			margin-top: 1px; // FIXME might be better solution
			color: var(--g-color-text-hint);
		}
	}
}

.mobile-navigation {
	padding: var(--g-spacing-2);

	&-avatar .g-button__text {
		padding: 0;
		padding-right: var(--g-spacing-2);
	}

	&_nav-items {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-auto-rows: 112px;
		gap: var(--g-spacing-2);
		&_button {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			word-break: break-all;
			text-overflow: ellipsis;
			padding: 0;
			&_content {
				display: flex;
				gap: var(--g-spacing-2);
				justify-content: center;
				flex-direction: column;
				align-items: center;
			}
		}
	}

	&_userpic {
		display: flex;
		justify-content: center;
		margin: var(--g-spacing-2);
	}

	&_modal {
		margin-bottom: 24rem;
	}

	&_popup {
		display: flex;
		flex-direction: column;
		padding: 8px;
		gap: 8px;
		z-index: 10;

		&_user {
			position: relative;
			display: flex;
			flex-direction: row;
			gap: var(--g-spacing-2);
			justify-content: space-between;
			align-items: center;
			padding: 8px;
			padding-top: 4px;
			border-bottom: 1.5px solid var(--g-color-line-generic);

			&_button-wrapper {
				display: flex;
				gap: 1rem;
			}

			&_button {
				display: flex;
				justify-content: flex-start;
				padding: 0 var(--g-spacing-4);

				&_logo {
					width: 24px;
				}
			}
		}

		&_avatar-wrapper {
			position: relative;
			display: flex;
		}

		&_avatar-workplace {
			position: absolute;
			background-color: darkred;
			border-radius: 100px;
			padding: 2.5px;
			bottom: -2.5px;
			right: -2.5px;
		}
	}

	&_content {
		margin-top: var(--spacing);
		margin-bottom: var(--spacing);
	}
}
