.checkins {
	--spacing: var(--g-spacing-4);
	--gap: var(--g-spacing-2);
	display: flex;
	justify-content: center;
	padding: var(--g-spacing-4);
	padding-top: 0;

	&_mobile {
		&_sheet .g-sheet__sheet-content>div {
			// FIXME is this ok?
			padding: var(--g-spacing-4);
		}
	}
}