.navigation-rail {
	display: flex;
	flex-direction: column;
	gap: calc(var(--spacing) * 4);
	top: 0;
	position: sticky;
	padding: var(--g-spacing-4);
  align-items: center;
	height: 100vh;

	&_fab {
		height: var(--g-base-height-xl); // FIXME
		width: var(--g-base-height-xl);
	}

	&_nav-items {
    & .g-button {
      display: block;
      margin-bottom: 1rem;
    }
	}

	&_flex-grower {
		flex-grow: 1;
	}

	&_userpic {
		display: flex;
		justify-content: center;
		:hover {
			cursor: pointer;
		}
		padding-bottom: 0.75rem;
	}

	&_popup-margin {
		margin-bottom: 2rem;
	}

	&_popup {
		display: flex;
		flex-direction: column;
		gap: var(--g-spacing-2);
		z-index: 10;
    padding: 8px;
		&_user {
			position: relative;
			display: flex;
      padding: 8px;
			align-items: center;
			justify-content: space-between;
			border-bottom: 1.5px solid var(--g-color-line-generic);
      width: 100%;
			&_clickable {
				position: absolute;
				cursor: pointer;
				top: 0;
				left: 20%;
				height: 100%;
				width: 60%;
			}
		}

		&_button {
      width: 100%;
			display: flex;
			justify-content: flex-start;
			padding: 0 var(--g-spacing-2); 
		}
	}

	&_content {
		margin-top: var(--spacing);
		margin-bottom: var(--spacing);
	}
}
