.cultural-event-form {
  width: 250px;
	&_duration-holder {
		position: relative;
		display: flex;
		justify-content: flex-end;
		margin-bottom: -1rem;
		width: 100%;
		&_duration-time {
			position: absolute;
		}
	}
  &_status-select {
    width: 100%;
  }
}
