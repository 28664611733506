.tariff {
  &_status {
    width: 100%;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    .yc-label__text {
      margin: 0 16px;
      justify-content: end;
      align-items: center;
    }
  }
}