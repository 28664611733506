.settings {
	padding: var(--g-spacing-2);
	display: grid;
	grid-template-columns: auto;
	gap: 8px;
	&_select-item {
		margin: 8px 0;
	}
	&_staff-item {
		min-height: 100px;
		min-width: 250px;
		&_name {
			max-width: 250px;
		}
	}
	&_add-staff {
		&_sheet .g-sheet__sheet-content > div {
			padding: var(--g-spacing-4);
		}
	}
}

.g-sheet {
	z-index: 1;
}

.role-label {
	user-select: none;
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}
