.cultural-event-container {
  &_controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--g-spacing-2);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: var(--g-color-base-float);
    &_button {
      display: flex;
      align-items: center;
    }
  }
}