.iteratee {
	&_button-wrapper {
		top: 0;
		left: 8px;
		display: flex;
		align-items: center;
		gap: 0.75rem;
	}
	&_button {
		display: flex;
		justify-content: flex-start;
		padding-left: 8px;
		&_logo {
			width: 32px;
			height: 32px;
		}
	}
}
